/*!
ugs_web js functions
*/

const offer_module = 'unigreenscheme.unigreenscheme.doctype.offer_to_buy.offer_to_buy';


frappe.ready(function() {
    function ugs_find_result(t) {
        // Build search string when search box submitted
        const search_string = t.val();
        if (!search_string) {
            return;
        }
        var search_link = t.attr('data-target');
        if (search_link === 'self') {
            search_link = window.location.href;
        }
        uri = URI(search_link);
        uri.setSearch('search', search_string);
        uri.removeSearch('start');
        window.location.href = uri.normalize();
    }

    function ugs_alert_message(txt) {
        if (txt) {
            $("#contact-alert").html(txt).toggle(true);
        }
    }

    $(".ugs-search-input").keyup(function(e) {
        // Enter/return on search box
        if (e.which===13) {
            ugs_find_result($(this));
        }
    });

    $('.ugs-search-icon').on('click', function() {
        // Click on magnifying glass in search box
        ugs_find_result($(this).siblings(".ugs-search-input"));
    });

    $('#offer-radio-group input').on('change', function() {
        // Hide/show offer price box
        if ($('#offer-radio-offer').prop('checked')) {
            // Show offer price box
            $('#offer-price-group').show();
        } else {
            // Hide offer price box and set to zero
            $('#offer-price-group').hide();
            $('#offer-price-group [name="offer"]').val(0);
        }
    });

    $('.ugs-currency-dropdown > a').on("click", function() {
        // Update the text and data-currency on the Offer currency selector
        const symbol = $(this).data('symbol');
        const currency = $(this).data('currency');
        const currency_button = $('.ugs-currency-button');
        currency_button.text(symbol);
        currency_button.data('currency', currency);
    });

    $('.btn-ugs-item-offer').on("click", function() {
        const item_code = $('[itemprop="productID"]').text();
        const email = $('[name="email"]').val();
        const name = $('[name="name"]').val();
        const company = $('[name="company"]').val();
        const offer = $('[name="offer"]').val();
        const currency = $('.ugs-currency-button').data('currency');
        const country = $('[name="country"]').val();
        const message = $('[name="message"]').val();

        if (!(email && name && country)) {
            ugs_alert_message(
                "Please enter your email, name and country \
                so that we can get back to you."
            );
            return false;
        }

        if (isNaN(offer)) {
            ugs_alert_message(
                "Please enter a valid offer price \
                (numbers only please)."
            );
            $('[name="offer"]').focus();
            return false;
        }

        if (!validate_email(email)) {
            ugs_alert_message(
                "Please enter a valid email address so that we can get back \
                to you."
            );
            $('[name="email"]').focus();
            return false;
        }

        const countries = $('#ugsCountryList').children().map(function() {
            return this.innerText}
        ).get()

        if (!countries.includes(country)) {
            ugs_alert_message(
                "Please enter a valid country"
            );
            $('[name="country"]').focus();
            return false;
        }

        $("#contact-alert").toggle(false);

        frappe.call({
            method: offer_module + ".create_offer",
            args: {
                email_id: email,
                item_code: item_code,
                offer_price: offer,
                currency: currency,
                country: country,
                customer_message: message,
                customer_name: name,
                customer_company: company
            }
        }).then(() => {
            // Success (no exception raised)
            if (offer && offer != '0') {
                ugs_alert_message("Your offer has been submitted.");
            } else {
                ugs_alert_message("Your request has been submitted.");
            }
            $(':input').val('');
        });
    });

});
